<!-- Informes APDS -->

<template>
  <div class="expReport" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">     
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>
                

        <!-- Botonera -->
        <div class="pt-2 pl-2">         
          <baseBtraExtra class="conflex"           
            :permExtra="permExtra"
            :modulo="btExtCfg"           
            @onEvent="execAccion">             
          </baseBtraExtra>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:950px">       
          <div class="conflex">
            <div class="columna" style="width:32%">

              <!-- Menú -->  
              <div class="cab">Tipos de Informes</div>
              <v-sheet v-bind="$cfg.styles.marco">        
                <v-treeview
                  :items="recordsRead[0]"
                  item-key="d"
                  item-text="n"
                  item-children="c"
                  activatable
                  dense
                  open-on-click
                  return-object
                  @update:active="changeInf">            
                </v-treeview>
              </v-sheet>
            </div>

            <div class="columna" style="width:66%;margin-left:10px">
              <div class="cab">Condiciones</div>

              <v-sheet v-bind="$cfg.styles.marco"> 
                <!-- Ordenado -->
                <div style="display:flex">
                    <v-select
                        v-bind="$select"
                        v-model="ct.ord[2]"
                        :label="ct.ord[1]"
                        :items="ord"
                        item-value="d"
                        item-text="n">
                    </v-select>                    
                </div>

                <!-- Tipo Servicio, Estado -->
                <div style="display:flex">
                    <v-select
                        style="flex: 0 0 50%"
                        v-bind="$select"
                        v-model="ct.tiposer[2]"
                        :label="ct.tiposer[1]"
                        :items="recordsRead[2]"
                        item-value="d"
                        item-text="n">
                    </v-select>
                    <v-select
                        style="flex: 0 0 50%"
                        v-bind="$select"
                        v-model="ct.estado[2]"
                        :label="ct.estado[1]"
                        :items="recordsRead[3]"
                        item-value="d"
                        item-text="n">
                    </v-select>
                </div>

                <!-- Situacion, Cierres -->
                <div style="display:flex">
                    <v-select
                        style="flex: 0 0 50%"
                        v-bind="$select"
                        v-model="ct.situacion[2]"
                        :label="ct.situacion[1]"
                        :items="recordsRead[4]"
                        item-value="d"
                        item-text="n">
                    </v-select>
                    <v-select
                        style="flex: 0 0 50%"
                        v-bind="$select"
                        v-model="ct.cierres[2]"
                        :label="ct.cierres[1]"
                        :items="recordsRead[5]"
                        item-value="d"
                        item-text="n">
                    </v-select>
                </div>

                <!-- Garantias, Servicios -->
                <div style="display:flex">
                    <v-select
                        style="flex: 0 0 50%"
                        v-bind="$select"       
                        label="Garantias"
                        :items="recordsRead[6]"
                        item-value="d"
                        item-text="n">
                          <template v-slot:selection="{ index }">              
                            <span  
                              v-if="index=== 0"              
                              class="grey--text caption">                
                                Incluidas <b>{{ ct.conGarantias[2].length }} </b> - 
                                Excluidas <b>{{ ct.sinGarantias[2].length }} </b>
                            </span>
                          </template>
                          <template v-slot:item="{ item }">              
                            <v-checkbox
                              v-bind="$checkbox"
                              v-model="ct.conGarantias[2]"
                              :value="item.d"
                              color="green">
                            </v-checkbox>
                            <v-checkbox                      
                              v-bind="$checkbox"
                              v-model="ct.sinGarantias[2]"
                              :value="item.d">
                            </v-checkbox>
                            <span>{{ item.n }}</span>
                          </template> 
                    </v-select>

                    <v-select
                        style="flex: 0 0 50%"
                        v-bind="$select" 
                        label="Servicios"                       
                        :items="recordsRead[7]"
                        item-value="d"
                        item-text="n">
                        <template v-slot:selection="{ index }">              
                          <span  
                            v-if="index=== 0"              
                            class="grey--text caption">                
                              Incluidas <b>{{ ct.conServicios[2].length }} </b> - 
                              Excluidas <b>{{ ct.sinServicios[2].length }} </b>
                          </span>
                        </template>
                        <template v-slot:item="{ item }">              
                          <v-checkbox
                            v-bind="$checkbox"
                            v-model="ct.conServicios[2]"
                            :value="item.d"
                            color="green">
                          </v-checkbox>
                          <v-checkbox                      
                            v-bind="$checkbox"
                            v-model="ct.sinServicios[2]"
                            :value="item.d">
                          </v-checkbox>
                          <span>{{ item.n }}</span>
                        </template> 
                    </v-select>
                </div>
                
                <!-- Periodo Fechas -->
                <div style="display:flex">
                  <uiMultiFilterFechas   
                    style="width:100%"                 
                    :items="recordsRead[13]"
                    :ct="ct"
                    :ctrl="ct.fecha"
                    tipo="periodo"
                    >
                  </uiMultiFilterFechas>                   
                </div>

                <!-- Expediente, Poliz, Ramo, Modalidad -->
                <div style="display:flex">  
                  <uiText      
                    style="width:25%"              
                    :label="ct.control[1]"
                    v-model="ct.control[2]">                    
                  </uiText>
                  <uiText      
                    style="width:25%"              
                    :label="ct.poliza[1]"
                    v-model="ct.poliza[2]">                    
                  </uiText>
                  <uiText      
                    style="width:25%"              
                    :label="ct.ramo[1]"
                    v-model="ct.ramo[2]">                    
                  </uiText>
                  <uiText      
                    style="width:25%"              
                    :label="ct.modalidad[1]"
                    v-model="ct.modalidad[2]">                    
                  </uiText>

                </div>                  

                <!-- Origen / Lugar -->
                <div style="display:flex">                    
                    <uiMultiple     
                      style="width:70%"
                      :ctrl="ct.ori"
                      :ct="ct">                        
                    </uiMultiple>
                    
                    <uiText      
                      style="width:30%"
                      :label="ct.region[1]"
                      v-model="ct.region[2]">                    
                    </uiText>
                </div>

                <!-- Empresa + TipoEmpresa-->                
                <div style="display:flex">                    
                    <uiMultiple     
                      style="flex: 0 0 100%"
                      :ctrl="ct.emp"
                      :ct="ct"
                      @change="changeCta">                        
                    </uiMultiple>
                </div>

                <!-- Funeraria -->
                <div style="display:flex">                              
                    <ctrlF style="flex: 0 0 100%" :ct="ct.fune"></ctrlF>                    
                </div>

                <!-- Central, Grupo -->
                <div style="display:flex">
                    <v-select
                        style="flex: 0 0 40%"
                        v-bind="$select"
                        v-model="ct.grupo[2]"
                        :label="ct.grupo[1]"            
                        :items="recordsRead[12]"
                        item-value="d"
                        item-text="n"          
                        multiple>                              
                        <template v-slot:selection="{ index }">              
                            <span  
                            v-if="index=== 0"              
                            class="grey--text caption">                
                                ({{ ct.grupo[2].length }} grupo/s seleccionado/s)
                            </span>
                        </template>
                    </v-select>        

                    <ctrlF style="flex: 0 0 60%" :ct="ct.central"></ctrlF>                    
                </div>

                <!-- Tipo Agencia + Agencia -->
                <div style="display:flex">  
                    <v-select
                        style="flex: 0 0 30%"
                        v-bind="$select"
                        v-model="ct.tipo_age[2]"
                        :label="ct.tipo_age[1]"
                        :items="recordsRead[9]"
                        item-value="id"
                        item-text="name"
                        :disabled="zona>0">
                    </v-select>   

                    <ctrlF style="flex: 0 0 70%" :ct="ct.agencia"></ctrlF>

                </div>

                <!-- Jefe zona (COS) -->
                <div style="display:flex">  
                    <v-select   
                        v-bind="$select"
                        v-model="ct.zona[2]"
                        :label="ct.zona[1]"
                        :items="itemsZonas"
                        item-value="id"
                        item-text="name"
                        :disabled="zona>0">
                    </v-select>   
                </div>

                 <!-- APD, Centro Tramitador -->
                <div style="display:flex">                     
                    <ctrlF style="flex: 0 0 50%" :ct="ct.apd"></ctrlF>

                    <v-select   
                        style="flex: 0 0 50%"
                        v-bind="$select"
                        v-model="ct.centro[2]"
                        :label="ct.centro[1]"
                        :items="itemsCentros"
                        item-value="id"
                        item-text="name">
                    </v-select>
                </div>

                <!-- Operador, Turno -->
                <div style="display:flex">                     
                    <ctrlF style="flex: 0 0 50%" :ct="ct.op"></ctrlF>

                    <v-select
                        style="flex: 0 0 50%"
                        v-bind="$select"
                        v-model="ct.turno[2]"
                        :label="ct.turno[1]"
                        :items="recordsRead[11]"
                        item-value="d"
                        item-text="n">
                    </v-select> 
                    
                </div>                
             
              </v-sheet>
            </div> 
          </div>          
        </div>
      </v-sheet>
    </div>
  </div>
</template>


<script>
  
  import { mixR } from "@/mixins/mixR.js";
  import baseHeader from "@/base/baseHeader";  
  import baseBtraExtra from "@/base/baseBtraExtra";  
  
  export default {
    mixins: [mixR],
    components: { baseHeader, baseBtraExtra },
    props: {},

    data() {
      return {
        stIni: {
          api: "expR",
          titulo:"Informe Facturación", 
          name:"frasR",
          mView:'',
          pView:[]
        },        
       
        ord: [],
        itemsZonas:[],
        

      };
    },

    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.btExtCfg.btnsAccion= [
          { accion:'verPdf', btn:"pdf" },
          { accion:'verExcel', btn:"excel" }
        ]; 
        
        // coordinadores de servicio
        this.itemsZonas= this.$store.state.datos_iniciales.zonas.slice();        
        this.itemsZonas.unshift({id:'0',name:'( Selecciona )'});

        this.itemsCentros= this.$store.state.datos_iniciales.ct.slice();        
        this.itemsCentros.unshift({id:'0',name:'( Centro Tramitador )'});

        //omision
        //this.ct.reg[2]='0';
        this.ct.estado[2]='0';
       
      },

      // si cambia cuenta en uimultiple
      changeCta(evt) {
          console.log('changeCta',evt);
          //if (!evt[4].comp) return;
      },

      //verPdf,verExcel,showReport en mixR

    }
  };
</script>
